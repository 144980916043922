import * as React from "react";
import { Modal, Button } from "semantic-ui-react";
import { VideoScript } from "../../../../../interfaces";
import copy from "copy-to-clipboard";
import "./styles.scss";

interface Props {
  open: boolean;
  videoScript: VideoScript;
  onClose?: () => void;
}

const VideoScriptModalComponent: React.FC<Props> = ({ open, videoScript, onClose }) => {
  const [copiedVideoScript, setCopiedVideoScript] = React.useState<boolean>(false);

  const copyVideoScript = (content: string) => {
    copy(content);
    if (copiedVideoScript) return;

    setCopiedVideoScript(true);
    setTimeout(() => {
      setCopiedVideoScript(false);
    }, 2000);
  };

  return (
    <Modal open={open} onClose={onClose} size={"tiny"} className={"videoScriptModal"}>
      <Modal.Header>{videoScript.title}</Modal.Header>

      <Modal.Description>
        {videoScript.content.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </Modal.Description>

      <Modal.Actions>
        <Button
          data-elm-id={"videoScriptModalCopyBtn"}
          className={"secondary rounded padded"}
          content={copiedVideoScript ? "Copied!" : "Copy"}
          icon={copiedVideoScript ? "checkmark" : "copy outline"}
          onClick={() => copyVideoScript(videoScript.content)}
        />
        <Button
          data-elm-id={"videoScriptModalCloseBtn"}
          className={"primary rounded"}
          content="Close"
          onClick={onClose}
        />
      </Modal.Actions>
    </Modal>
  );
};

export const VideoScriptModal = VideoScriptModalComponent;
