import { Media, MediaGroup, PlayerAccount, Post, SocialNetworkMessage, SocialNetworksId } from "..";

export enum VideoProjectStatus {
  SUBMITTED_FOR_EDITING = "SUBMITTED_FOR_EDITING",
  READY_TO_POST = "READY_TO_POST",
  SCHEDULED = "SCHEDULED",
  POSTED = "POSTED",
  POST_FAILED = "POST_FAILED",
}

export enum VideoProjectRoleType {
  PLAYER = "PLAYER",
  COACH = "COACH",
  EDITOR = "EDITOR",
}

export interface BaseVideoProject {
  videoProjectId?: number;
  videoScriptId?: number;
  coachId?: string;
  playerId: number;
  createdDate?: string;
  status: VideoProjectStatus;
  statusLabel?: string;
  statusDate?: string;
  originalMedia?: MediaGroup;
  editedMedia?: MediaGroup;
  messages?: VideoProjectMessage[];
  editable?: boolean;
}

export interface VideoProjectWithPlayer extends VideoProject {
  playerAccount: PlayerAccount;
}

export interface VideoCatalystStatus {
  enabled: boolean;
  creditsUsed?: number | null;
  creditsRemaining?: number | null;
  unlimitedCredits: boolean;
  readyToPostCount: number;
}
export interface VideoProject extends BaseVideoProject {
  postId?: number;
  preferredPostDate?: string;
  editedBy?: string;
  editedDate?: string;
  isTestProject?: boolean;
  isAutoPost?: boolean;
  post?: Post;
  autoCaption?: boolean;
  allowPlayerHashtags?: boolean;
  allowShortLink?: boolean;
  allowComplianceDisclaimer?: boolean;
}

export interface VideoProjectMessage {
  videoProjectMessageId: number;
  userId: number;
  userName: string;
  userRole: VideoProjectRoleType;
  content: string;
  createdDate: string;
}

export interface AkcElement {
  videoProjectAckId: number;
  ackType: "MESSAGE";
  ackFlag: boolean;
  ackDate: string;
  userRole: string;
}
export interface VideoProjectDetail extends BaseVideoProject {
  postId?: number;
  preferredPostDate?: string;
  postedDate?: string;
  editedBy?: string;
  editedDate?: string;
  isTestProject?: boolean;
  isAutoPost?: boolean;
  message: string;
  messageToPost?: string;
  socialNetworks?: SocialNetworksId[];
  autoCaption?: boolean;
  allowPlayerHashtags?: boolean;
  allowShortLink?: boolean;
  allowComplianceDisclaimer?: boolean;
  post?: Post;
  clientNotes?: ClientNotes;
  preferences?: VideoPreferences;
  ackList?: AkcElement[];
  videoScript?: VideoScript;
}

export interface VideoPreferences {
  captions: boolean;
  emojis: boolean;
  broll: boolean;
  music: boolean;
  captionColors: string;
  tone?: VideoPreferencesTone;
  musicGenre?: VideoPreferencesMusicGenre;
}

export enum VideoPreferencesMusicGenre {
  JAZZ = "JAZZ",
  CHILL = "CHILL",
  ROCK = "ROCK",
  LOFI = "LOFI",
  LOUNGE = "LOUNGE",
  HIPHOP = "HIPHOP",
  LATIN = "LATIN",
}

export enum VideoPreferencesTone {
  FUN = "FUN",
  PROFESSIONAL = "PROFESSIONAL",
  SERIOUS = "SERIOUS",
}

export interface VideoScript {
  videoScriptId: number;
  coachId?: number;
  playerId?: number;
  createdDate?: string;
  title: string;
  content: string;
}

export enum VideoProjectSource {
  PLAYER_WEB = "PLAYER_WEB",
  COACH_WEB = "COACH_WEB",
  PLAYER_MOBILE = "PLAYER_MOBILE",
}

export interface ClientNotes {
  notes: string;
  playerId: number;
}
export interface VideoProjectMessageRequest {
  content: string;
}

export interface VideoProjectRequest extends VideoProject {
  socialNetworkMessages: SocialNetworkMessage[];
  messageToPost: string;
  hashtags?: string;
  initialMessages?: VideoProjectMessageRequest[];
  originalMediaGroup: MediaGroup;
  editedMediaGroup?: MediaGroup;
  isAutoPost?: boolean;
  scriptId?: string;
  preferences?: VideoPreferences;
  source?: VideoProjectSource;
}

export interface PlayerVideoProjectResponse extends BaseVideoProject {
  thumbnail?: Media;
  originalMedia?: MediaGroup;
  editedMedia?: MediaGroup;
  playerName?: string;
  companyName?: string;
  editedBy?: string;
  editedDate?: string;
  needsAck?: boolean;
  post?: Post;
}

export interface PlayerResumeResponse {
  fullName: string;
  playerId: number;
  userId: number;
}

export interface VideoProjectPlayerSummary {
  playerId: number;
  playerName: string;
  playerEmail: string;
  enabled: boolean;
  creditsRemaining: number;
  unlimitedCredits: boolean;
}

export interface VideoProjectUpdateRequest extends VideoProject {
  socialNetworkMessages: SocialNetworkMessage[];
  messageToPost: string;
  hashtags?: string;
  editedMediaGroup?: MediaGroup;
  isAutoPost?: boolean;
  autoCaption?: boolean;
}

export interface VideoProjectCoachConfig {
  defaultPlayerVideoCredits?: number;
  newCycleDay?: number;
}

export interface VideoProjectPlayerConfigRequest {
  monthlyVideoCredits?: number;
  unlimited?: boolean;
  resetToDefault?: boolean;
}

export interface VideoProjectUsageReportRequest {
  startDate: string;
  endDate: string;
}

export interface VideoProjectPlayerUsage {
  playerId: number;
  playerName: string;
  playerEmail: string;
  currentlyEnabled: boolean;
  currentCreditLimit: number | undefined;
  creditsUsed: number;
}

export interface VideoProjectUsageReport {
  startDate: string;
  endDate: string;
  playerUsage: VideoProjectPlayerUsage[];
}
